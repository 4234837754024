import React from "react";
import {Link } from "react-router-dom";
import './components/styles.scss';

function Navbar() {
  return (
    <nav>
        <div className='navbar'>
          <Link to="/">Home</Link>
          <Link to="/daytrade">Day-Trade</Link>
          <Link to="/highlow">High-Low</Link>
          <Link to="/news">News</Link>
          <Link to="/analysis">Analysis</Link>
        </div>
    </nav>
  );
}

export default Navbar;