import React from 'react';

// This puts a live clock with long date and seconds on a web page

//Set variable
let options = { weekday:'long', year:'numeric', month:'long', day:'numeric', hour12: false, hour: "numeric", minute: "numeric", second:"numeric"  };
options.timeZone = 'australia/brisbane';
options.timeZoneName = 'short';

class Clock extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        time: new Date().toLocaleString('en-AU', options)
      };
    }
    componentDidMount() {
      this.intervalID = setInterval(
        () => this.tick(),
        1000
      );
    }
    componentWillUnmount() {
      clearInterval(this.intervalID);
    }
    tick() {
      this.setState({
        time: new Date().toLocaleString('en-AU', options)
      });
    }
    render() {
      return (
        <div>
            {/* add text here if you want it in front of the time */}
            {this.state.time}
            {/* add text here if you want it placed after the time */}
        </div>
      );
    }
  }

export default Clock;