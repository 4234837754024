import React from 'react';
import Clock from '../components/Clock';
import Quotes from '../components/Quotes';
import WorldClocks from '../components/WorldClocks';
import '../components/styles.scss';
import HighLowChart from '../components/HighLowChart';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";


// The current charts pulling from Yahoo Finance need "CORS Anywhere" run locally (see notes)
// Add tickers in sets of x4 for visual excellence, add page scroll refs -  (+ ZW, ZC, ZS, ZC)
// previous working x4 pages - ['6A=F','6E=F','6B=F','6J=F','6C=F','MBT=F','MET=F','SIL=F','GC=F','CL=F','QG=F','QM=F','MES=F','MNQ=F','MYM=F','M2K=F']; 

// HighLow shows the high and low of candles in a timeframe. This is to ascertain the mid-point, looking for trend-forming signals.

const tickers = ['6A=F','6E=F','6B=F','6J=F','6C=F','MBT=F','MET=F','SIL=F','GC=F','CL=F','QG=F','QM=F','GE=F','HG=F','UB=F','^VIX','ZT=F', 'ZF=F', 'ZN=F', 'ZB=F','MES=F','MNQ=F','MYM=F','M2K=F']; // x6 pages of 4x4 rows
const rowone = ['30m', '1d'];
//const rowtwo = ['30m', '5d'];
//const rowthree = ['15m', '2d'];
//const rowfour = ['5m', '6h'];


function App() {

  //This is to create unlimited scroll refs points on the page. Call from buttons with scrollClick(x)
  // scrollRef[0] is top, scrollRef[1] is bottom, the rest are in order
  const scrollRef = [];
  const scrollClick = (index) => {    
    console.log(scrollRef[index]);
    scrollRef[index].scrollIntoView(); 
  } 

  return (
  <div>
    <section className="section">
      <div className="box-main">
        <div className="firstHalf">
          <div className ="top-line">
            <div className = "todayClock">
              <Clock></Clock>
            </div>
            <div className = "quote-text">
              <Quotes></Quotes> 
            </div>             
          </div>
          <div>
            <div><WorldClocks></WorldClocks></div>
            <button className='scroll-button' onClick={() => scrollClick(6)}>Go To Last Page<FaAngleDown className='faIcon-ad'/></button>            
          </div>
          <p className="clickTitle-centred" onClick={() => scrollClick(0)}>
              --- High-Low Candles Only  ---              
          </p>
        </div>
      </div>
    </section>
    <div ref={(ref) => { scrollRef[0] = ref }}>
      <div className = 'grnText-seperator'>
      <button className='scroll-button' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>Go To Top <FaAngleUp className='faIcon-au'/></button>
        Page 1- High-Low Candle Charts 	&#40;Shaded area is volume, horizontal line is avg Vol; click for data&#41;
        <button className='scroll-button' onClick={() => scrollClick(2)}>Go To Page 2 <FaAngleDown className='faIcon-ad'/></button>       
      </div>
        <div className='candlestick-container'>      
          <div className="candlestick-chart">
              <HighLowChart symbol={tickers[0]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
              <HighLowChart symbol={tickers[1]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
              <HighLowChart symbol={tickers[2]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
              <HighLowChart symbol={tickers[3]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
        <div className='candlestick-container'>      
          <div className="candlestick-chart">
            <HighLowChart symbol={tickers[4]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[5]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[6]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[7]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
        <div className='candlestick-container'>      
          <div className="candlestick-chart">
            <HighLowChart symbol={tickers[8]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[9]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[10]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[11]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='grnCandlechart-border'>
            <HighLowChart symbol={tickers[12]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[13]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[14]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[15]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
    </div>
    <div ref={(ref) => { scrollRef[2] = ref }}>
      <div className='pnkText-seperator'>
        <button className='scroll-button' onClick={() => scrollClick(0)}>Go To Page 1 <FaAngleUp className='faIcon-au'/></button>
        Page 2 - High Low Candle Charts 
        <button className='scroll-button' onClick={() => scrollClick(3)}>Go To Page 3 <FaAngleDown className='faIcon-ad'/></button>      
      </div>
        <div className='candlestick-container'>      
          <div className="candlestick-chart">
            <HighLowChart symbol={tickers[16]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[17]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[18]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[19]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">
            <HighLowChart symbol={tickers[20]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[21]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[22]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
            <HighLowChart symbol={tickers[23]} interval={rowone[0]} range={rowone[1]} className='candlestick-chart'/>
          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='pnkCandlechart-border'>

          </div>
        </div>
    </div>
    <div  ref={(ref) => { scrollRef[3] = ref }}>
      <div className = 'grnText-seperator'>
        <button className='scroll-button' onClick={() => scrollClick(2)}>Go To Page 2 <FaAngleUp className='faIcon-au'/></button>
        Page 3 - Candlestick Charts 
        <button className='scroll-button' onClick={() => scrollClick(4)}>Go To Page 4 <FaAngleDown className='faIcon-ad'/></button>      
      </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='grnCandlechart-border'>

          </div>
        </div>
    </div>
    <div ref={(ref) => { scrollRef[4] = ref }}>
      <div className = 'pnkText-seperator'>
        <button className='scroll-button' onClick={() => scrollClick(3)}>Go To Page 3 <FaAngleUp className='faIcon-au'/></button>
        Page 4 - Candlestick Charts 
        <button className='scroll-button' onClick={() => scrollClick(5)}>Go To Page 5 <FaAngleDown className='faIcon-ad'/></button>      
      </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='pnkCandlechart-border'>

          </div>
        </div>
    </div>
    <div ref={(ref) => { scrollRef[5] = ref }}>
      <div className = 'pnkText-seperator'>
        <button className='scroll-button' onClick={() => scrollClick(4)}>Go To Page 4 <FaAngleUp className='faIcon-au'/></button>
        Page 5 - Candlestick Charts 
        <button className='scroll-button' onClick={() => scrollClick(6)}>Go To Page 6 <FaAngleDown className='faIcon-ad'/></button>      
      </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='pnkCandlechart-border'>

          </div>
        </div>
    </div>
    <div ref={(ref) => { scrollRef[6] = ref }}>
      <div className = 'pnkText-seperator'>
        <button className='scroll-button' onClick={() => scrollClick(5)}>Go To Page 5 <FaAngleUp className='faIcon-au'/></button>
        Page 6 - Candlestick Charts 
        <button className='scroll-button' onClick={() => scrollClick(0)}>Go To Page 1 <FaAngleUp className='faIcon-au'/></button>      
      </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className ="candlestick-chart">

          </div>
        </div>
        <div className='candlestick-container'>      
          <div className='pnkCandlechart-border'>

          </div>
        </div>
    </div>

    <div className='grnText-seperator' ref={(ref) => { scrollRef[1] = ref }}>
          Bottom of page <button className='scroll-button' onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>Go To Top <FaAngleUp className='faIcon-au'/></button> 
      </div>
      
  </div> 
  );
}

export default App;
