import React from 'react';
import axios from 'axios';
import { Chart } from "react-google-charts";

// This uses react-google-charts to create candlestick charts. it requires the symbol, interval, and range to be sent as arguments(e.g: M6A=F, 15m, 1d)

// This is installed from https://github.com/ViktorMS/yahoo for pulling data from Yahoo Finance free URL
// This needs either the limited "CORS Anywhere" set to run during development  - https://cors-anywhere.herokuapp.com/corsdemo
// Or use "node cors" run from windows command prompt cd into folder with cors.js then works with http://localhost:8080/ on front of URL

//"this.props.symbol" is sent from the App.js call with - "<SmallChart symbol="M6A=F"/>""

//add this back into axios.get for working in development - http://localhost:8080/


export default class CandleChart extends React.Component {
  state = {
    chartData: [],
    change: 1, //this can be removed to tidy it up, not currently used
  }

  componentDidMount() {
    axios.get(`https://mdkbcors.herokuapp.com/https://query2.finance.yahoo.com/v8/finance/chart/${this.props.symbol}?region=US&lang=en-US&includePrePost=&interval=${this.props.interval}&range=${this.props.range}`)
      .then(res => {
        //console.log(res.data.chart.result[0])
        const timestamps = res.data.chart.result[0].timestamp;//Get number of chart entries to cycle through
        const quote = res.data.chart.result[0].indicators.quote[0];//Get all OHLC values in one request to save hits
        //console.log('volume:', quote.volume);
        //console.log('quote close:', quote.close); //show drilled down quote for OHLCV
        let trueRange = [] //array for ATR calcs to hold High, Low, Close values
        const atrTimePeriod = 14 //minimum number of candles to look back at for ATR Calcs 
        const atrMultiple = 2.5 // How much to displace the ATR Stop either side of price by
        let atr = '' //may need to zero this each loop
        let atrStopa = ''//atrStopa above price
        let atrStopb = ''//atrStopb below price
        let prevAtrStopa = ''// store for artStopa, when API data is null use this instead until not null
        let prevAtrStopb = ''

        let chartData = [["Timestamp", "L,H,O,C", "Open", "Close", "High", "Volume", "AvgVol", "atrStopa: " + atrMultiple, "atrStopb: " + atrMultiple]]
        timestamps.forEach(
          (element, index) =>  {
            const date = new Date(element*1000); // Unix time in ms
            const prettyDate = date.toLocaleString('en-GB', {weekday: 'short', day: 'numeric', month: 'short', year: '2-digit', hour12: 'true', hour: 'numeric', minute: 'numeric'})             
            const totalVol = quote.volume.reduce(function(previousValue, currentValue) { return ( previousValue + currentValue) }, 0)
            const avgVol = Math.round(totalVol / quote.volume.length) //this calculates the avg volume and rounds it to nearest integer

            //*** CALCULATE THE ATR TRAILING STOP***// 
            //Maths calcs to get True Range (when API chart data is !== null. Also assumes the first chart entry is never null):
            let tr1 = (quote.high[index] - quote.low[index]) 
            let tr2 = Math.abs(quote.high[index] - quote.close[index])   
            let tr3 = Math.abs(quote.close[index] - quote.low[index])    
            trueRange.push(Math.max(tr1, tr2, tr3))          

            // Use the array of trueRange Values to get the Simple Moving Average of the true range, ie., ATR:
            if (atrTimePeriod >= trueRange.length) { // if the atrTimePeriod is greater then the entire dataset, just return the average of the whole set
              atr = (( trueRange.reduce(( accumulator, currentValue ) => accumulator + currentValue, 0 ) ) / trueRange.length)
            }
            else {let nData = trueRange.slice((atrTimePeriod * -1)) //.slice uses atrTimePeriod limit to count numbers of candles to include in ATR
              atr = (nData.reduce(( accumulator, currentValue ) => accumulator + currentValue, 0 )  / atrTimePeriod   )   
            }

            let xAtr = atr * atrMultiple //calc artStop's distance away
            //if price closes below the atrStopa:
            if (quote.close[index] !== null) //Check API data is not null
            {
              //if (quote.close[index] < prevClose) {
                atrStopa = (quote.close[index] + xAtr) //if quote.close is less than prevClose then add xAtr to close
              //}
              //else {
                atrStopb = (quote.close[index] - xAtr) //else subtract xAtr from close
              //}
            }
            else {// if API data is null then reuse previous data
                atrStopa = prevAtrStopa
                atrStopb = prevAtrStopb
            }            


            chartData.push([prettyDate,quote.low[index],quote.open[index],quote.close[index],quote.high[index],quote.volume[index],avgVol,atrStopa,atrStopb])            
            if (quote.close[index] != null)//this assumes that if the other fields are null then close is also null, and so won't overwrite prev data with null
            {
              //prevClose = quote.close[index] //update prevClose with this close 
              prevAtrStopa = atrStopa //update prevAtrStopa with valid data
              prevAtrStopb = atrStopb
            }
          }
          );
          //console.log('chartData:', chartData)
          this.setState({ chartData });
      })
      
  }

  render() {
    let color = '#A0A0A0'; // color of candle stalks (I think)
    //if(this.state.change >= 1.01) color = 'green';
    //if(this.state.change <= 0.99) color = 'red';
    const options = {
      backgroundColor: {fill: "#323259", stroke: 'black', strokeWidth: 4},
      chartArea: {width: '95%', height: '85%'},
      title: this.props.symbol + ': ' + this.props.interval + ',' + this.props.range,
      titleTextStyle: {color: 'white'},
      legend: 'none',
      tooltip: {trigger: 'selection'},
      candlestick: {
        fallingColor: { strokeWidth: 0, fill: "#FF4040" }, // red
        risingColor: { strokeWidth: 0, fill: "#00D68F" }, // green
      },
      axisFontSize : 0,
      hAxis: {
        baselineColor: 'none',
        ticks: [],
        textPosition: 'none'
      },
      vAxis: {
        baselineColor: 'none',
        ticks: [],
        textPosition: 'none'
      },
      colors: [color],
      series: {
        1: {type: 'area', targetAxisIndex: '1', lineWidth: 0, color: '#00d6d5', areaOpacity: 0.2}, // area to show volume on chart
        2: {type: 'line', targetAxisIndex: '1', lineWidth: 0.3, color: '#00d6d5'},// horizontal line shows avgVol of chart
        3: {type: 'line', targetAxisIndex: '0', lineWidth: 0.4, color: '#a16eff'},// line to show atrStopa above the price
        4: {type: 'line', targetAxisIndex: '0', lineWidth: 0.4, color: '#a16eff'},// line to show atrStopb below the price         
      }
    };

    return (                       
            <Chart
              chartType="CandlestickChart"
              data={this.state.chartData}
              width="24.5vw" //set this to 25% of viewport for x4 charts in a row
              height="12.5vw" //195px if it isnt working. This auto-set to 200px in development with width set to 24.5vw
              options={options}              
              />
            )
  }
}