import React from "react";

class QuoteAndAuthor extends React.Component {
  render() {
    //const randomColor = this.props.displayColor();
    //const html = document.documentElement;
    //html.style.backgroundColor = randomColor;

    // removed the below section to get it to sit right, but can delete it once I know it is working okay
    //<div id="quote">"{this.props.quote}"</div>
    //<div id="author"> - {this.props.author ? this.props.author : "Unknown"} </div>

    return (
      <div className = "quote-text" key={Math.random()} >     
          <div>"{this.props.quote}"- {this.props.author ? this.props.author : "Unknown"} </div>
        <button className="quote-button" id="newquote" onClick={this.props.handleClick}> New quote </button>
      </div>
    );
  }
}

export default QuoteAndAuthor;

