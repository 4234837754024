import React from 'react';
import axios from 'axios';
import { Chart } from "react-google-charts";

// This uses react-google-charts to create candlestick charts. it requires the symbol, interval, and range to be sent as arguments(e.g: M6A=F, 15m, 1d)

// This is installed from https://github.com/ViktorMS/yahoo for pulling data from Yahoo Finance free URL
// This needs either the limited "CORS Anywhere" set to run during development  - https://cors-anywhere.herokuapp.com/corsdemo
// Or use "node cors" run from windows command prompt cd into folder with cors.js then works with http://localhost:8080/ on front of URL

//"this.props.symbol" is sent from the App.js call with - "<SmallChart symbol="M6A=F"/>""

//add this back into axios.get for working in development - http://localhost:8080/

//This version needs to use the High and Low for the candles and discard the Open and Close. All candles will be same color fill.
// Simplest work around was to set Open and Close, to High and Low.

export default class HighLowChart extends React.Component {
  state = {
    chartData: [],
    change: 1,
  }

  componentDidMount() {
    axios.get(`https://mdkbcors.herokuapp.com/https://query2.finance.yahoo.com/v8/finance/chart/${this.props.symbol}?region=US&lang=en-US&includePrePost=&interval=${this.props.interval}&range=${this.props.range}`)
      .then(res => {
        //console.log(res.data.chart.result[0])
        const timestamps = res.data.chart.result[0].timestamp;
        const quote = res.data.chart.result[0].indicators.quote[0];//Get all OHLC values in one request to save hits
        //console.log('volume:', quote.volume);
        //console.log('quote close:', quote.close); //show drilled down quote for OHLCV
        let chartData = [["Timestamp", "Low", "Open", "Close", "High", "Volume", "AvgVol"]]
        timestamps.forEach(
          (element, index) =>  {
            const date = new Date(element*1000); // Unix time in ms
            const prettyDate = date.toDateString()
            //next line calculates total of all quote.volume items
            const totalVol = quote.volume.reduce(function(previousValue, currentValue) { return ( previousValue + currentValue) }, 0)
            //console.log('totalVol:', totalVol)
            const avgVol = Math.round(totalVol / quote.volume.length) //this calculates the avg volume and rounds it to nearest integer
            //console.log('avgVol:', avgVol)

            //Set Open to Low and Close to High in below line (order was L, O, C, H. Is now L,L, H,H) to return full candle
            chartData.push([prettyDate,quote.low[index],quote.low[index],quote.high[index],quote.high[index], quote.volume[index], avgVol])
          }
          );
          //console.log('chartData:', chartData)
          this.setState({ chartData });
      })
      
  }

  render() {
    let color = '#00D6D5'; // Set all colors to blue for fill.
    //if(this.state.change >= 1.01) color = 'green';
    //if(this.state.change <= 0.99) color = 'red';
    const options = {
      backgroundColor: {fill: "#323259", stroke: 'black', strokeWidth: 4},
      chartArea: {width: '95%', height: '85%'},
      title: this.props.symbol + ': ' + this.props.interval + ',' + this.props.range,
      titleTextStyle: {color: 'white'},
      legend: 'none',
      tooltip: {trigger: 'selection'},
      candlestick: {
        fallingColor: { strokeWidth: 0, fill: "#00D6D5" }, // set all colors to blue for fill
        risingColor: { strokeWidth: 0, fill: "#00D6D5" },
      },
      axisFontSize : 0,
      hAxis: {
        baselineColor: 'none',
        ticks: [],
        textPosition: 'none'
      },
      vAxis: {
        baselineColor: 'none',
        ticks: [],
        textPosition: 'none'
      },
      colors: [color],
      series: {
        1: {type: 'area', targetAxisIndex: '1', lineWidth: 0, color: '#00d6d5', areaOpacity: 0.2}, // area to show volume on chart
        2: {type: 'line', targetAxisIndex: '1', lineWidth: 0.3, color: '#00d6d5'}// horizontal line shows avgVol of chart
      }// this is for avg volume on the chart
    };

    return (                       
            <Chart
              chartType="CandlestickChart"
              data={this.state.chartData}
              width="24.5vw" //set this to 25% of viewport for x4 charts in a row
              height="12.5vw" //195px if it isnt working. This auto-set to 200px in development with width set to 24.5vw
              options={options}              
              />
            )
  }
}