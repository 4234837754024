import axios from "axios";
import { useState, useEffect } from "react";

//http://localhost:8080/https://www.forexlive.com/feed
//https://www.forexlive.com/feed
//https://news.google.com/news/rss
//https://news.google.com/rss/search?q=when:24h+allinurl:bloomberg.com&hl=en-AU&gl=AU&ceid=AU:en
//https://news.google.com/rss/search?q=when:24h+allinurl:bloomberg.com&hl=en-US&gl=US&ceid=US:en
//https://search.cnbc.com/rs/search/combinedcms/view.xml?partnerId=wrss01&id=10000664

//trying to get rid of this line <button onClick={getRss}>Refresh the RSS feed</button>

export default function NewsFeed({siteURL}) {
  
  const [items, setItems] = useState([]); 

useEffect(() => {
    const getRss = async () => {      
      const res = await axios.get(siteURL);      
      //console.log('Res:', res);
      //console.log('Res.Data:', res.data);
      const feed = new window.DOMParser().parseFromString(res.data, "text/xml");
      //console.log('Feed:', feed);
      const items = feed.querySelectorAll("item");
      //console.log('Items:', items);
      const feedItems = [...items].map((el) => ({             
        link: el.querySelector("link").innerHTML,
        pubDate: el.querySelector("pubDate").innerHTML,
        title: el.querySelector("title").innerHTML,
      }));
      setItems(feedItems);
      //console.log('feedItems:', feedItems);
    };
    getRss();
    // eslint-disable-next-line
  }, []);

      return (
        <div>                                             
          {items.map((item) => {
            return (
              <div key ={item.link}>        
                {new Date(item.pubDate.replace(/Z/gi, "UTC")).toLocaleDateString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: 'false' })} - {item.title} -
                <a href={item.link}> Link</a>
              </div>
            );
          })}
        </div>
      );
};
 



