import React from "react";
import './components/styles.scss';
import Navbar from './Navbar';
//import Footer from './Footer';
import Home from './pages/Home';
import DayTrade from './pages/DayTrade';
import HighLow from './pages/HighLow';
import News from './pages/News';
import Analysis from './pages/Analysis';
import NoPage from './pages/NoPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Navbar/>
          <div>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/daytrade" element={<DayTrade />} />
              <Route path="/highlow" element={<HighLow />} />
              <Route path="/news" element={<News />} />
              <Route path="/analysis" element={<Analysis />} />
              <Route path= '*' element={<NoPage />} />

            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;