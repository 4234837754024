import React, { useState } from "react";
import NewsFeed from '../components/NewsFeed';
import '../components/styles.scss';
import ToDoList from "../components/ToDoList";

//for development add this back into NewsFeed siteURL="http://localhost:8080/

//const stockSelect = "SPY,QQQ,DIA,VIX"; //these will show in stocktwits window

const econCalURL = "https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=timeselector&countries=25,34,32,6,37,72,71,22,17,51,39,14,33,10,35,43,60,38,36,110,11,26,9,12,4,5&calType=day&timeZone=91&lang=70"

//const stocktwitsURL = "//api.stocktwits.com/widgets/stream?width=900&height=600&domain=finviz.com&scrollbars=1&streaming=true&header=0&limit=50&symbol=" + stockSelect + "&border_color=transparent&border_color_2=transparent&box_color=transparent&header_text_color=ffffff&divider_color=d3d3d3&stream_color=transparent&username_font=Verdana%2C%20Arial%2C%20Tahoma&username_size=11&divider_type=solid&link_color=4871a8&link_hover_color=4871a8&font=Verdana%2C%20Arial%2C%20Tahoma&font_size=11&text_color=000000&time_color=999999&time_font_size=10";

function News() {

    const [checked, setChecked] = useState([]);
    const stockList = ["SPY", "NASDAQ", "DIA", "VIX", "RUT", "DXY", "AUDUSD", "EURUSD", "GBPUSD", "JPYUSD", "CADUSD", "XAUUSD", "XAGUSD", "HG_F", "NG_F", "CL_F", "XLE", "UAN", "LXU", "ZC_F", "ZB_F","ZN_F","ZF_F","ZT_F"];

    // Add/Remove checked item from list
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
        updatedList = [...checked, event.target.value];
        } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    // Generate string of checked items
    const checkedItems = checked.length
        ? checked.reduce((total, item) => {
            return total + ", " + item;
        })
        : "";

    // Return classes based on whether item is checked
    var isChecked = (item) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";
    
    return (
    <div>
        <h1>News & Economic Calendar</h1>
        <h2>Allow page a moment to refresh...</h2>
        <div className = 'grnText-seperator'>
            <div>
                Economic Calendar and StockTwits
            </div>            
        </div>
        <div className="news-topgrid">
            <div className="news-topgridItems">
                <iframe 
                    title='Investing.com - Economic Calendar' 
                    src={econCalURL} 
                    width="645px"
                    max-width="645px" 
                    height="600"                    
                    allowtransparency="true"
                    //frameborder="0"  
                    //marginwidth="0" 
                    //marginheight="0"
                    >
                </iframe>    
            </div>            
            <div>
                <div style={{color: 'white', width: '90px'}}>
                    <div> &#160;Checklist:&#160; </div>
                        <div>
                        {stockList.map((item, index) => (
                            <div key={index}>
                                <input value={item} type="checkbox" onChange={handleCheck} />
                                <span className={isChecked(item)}>{item}</span>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>

            <div className="news-topgridItems">
                <iframe
                    title='Stocktwits'
                    src={"//api.stocktwits.com/widgets/stream?width=645&height=600&domain=finviz.com&scrollbars=1&streaming=true&header=0&limit=50&symbol=" + checkedItems + "&border_color=transparent&border_color_2=transparent&box_color=transparent&header_text_color=ffffff&divider_color=d3d3d3&stream_color=transparent&username_font=Verdana%2C%20Arial%2C%20Tahoma&username_size=11&divider_type=solid&link_color=4871a8&link_hover_color=4871a8&font=Verdana%2C%20Arial%2C%20Tahoma&font_size=11&text_color=000000&time_color=999999&time_font_size=10"}
                    width="645px"
                    height="600"
                    allowtransparency="true"
                    background= "#FFFFFF"
                    style={{backgroundColor: '#FFFFFF'}}
                    //frameborder="0"  
                    //marginwidth="0" 
                    //marginheight="0"
                    >           
                </iframe>
            </div>
            <div><ToDoList listName="notes"/></div>
        </div>
        <div className = 'pnkText-seperator'>
            <div>
                RSS Feeds:
            </div>            
        </div>
        <div className='rss-container'>
            <p>ForexLive RSS Feed:</p>
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://www.forexlive.com/feed'/>
            </div>
            <p>FXStreet RSS Feed:</p>             
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://xml.fxstreet.com/news/forex-news/index.xml '/>
            </div>

            <p>Seeking Alpha RSS Feed:</p>
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://seekingalpha.com/market_currents.xml'/>
            </div>
            <p>CNBC RSS Feed:</p>
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://search.cnbc.com/rs/search/combinedcms/view.xml?partnerId=wrss01&id=10000664'/>
            </div>
            <p>FT RSS Feed:</p>
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://www.ft.com/?format=rss'/>
            </div>
            <p>Fortune RSS Feed:</p>             
            <div className='rss-box'>                                
                <NewsFeed siteURL='https://mdkbcors.herokuapp.com/https://fortune.com/feed/'/>
            </div>                     
        </div>

    </div>
    );
}

export default News;