const quotes=[
    {
    "quote": "Fate is in your hands and no one elses",
    "author": "Byron Pulsifer"
    }, {
	"quote": "Every man dies. Not every man really lives.",
	"author": ""
	}, {
	"quote": "Doing nothing is better than being busy doing nothing.",
	"author": "Lao Tzu"
	}, {
	"quote": "Study the past, if you would divine the future.",
	"author": "Confucius"
	}, {
	"quote": "From error to error one discovers the entire truth.",
	"author": "Sigmund Freud"
	},{
	"quote": "Well done is better than well said.",
	"author": "Benjamin Franklin"
	}, {
	"quote": "Bite off more than you can chew, then chew it.",
	"author": "Ella Williams"
	}, {
	"quote": "Never mistake activity for achievement.",
	"author": "John Wooden"
	}, {
	"quote": "One faces the future with ones past.",
	"author": "Pearl Buck"
	}, {
	"quote": "The time will never be just right.",
	"author": "Napoleon Hill"
	}, {
	"quote": "One fails forward toward success.",
	"author": "Charles Kettering"
	}, {
	"quote": "Time is the wisest counsellor of all.",
	"author": "Pericles"
	}, {
	"quote": "You give before you get.",
	"author": "Napoleon Hill"
	}, {
	"quote": "Without courage, wisdom bears no fruit.",
	"author": "Baltasar Gracian"
	}, {
	"quote": "What you fear is that which requires action to overcome.",
	"author": "Byron Pulsifer"
	}, {
	"quote": "The best teacher is experience learned from failures.",
	"author": "Byron Pulsifer"
	}, {
	"quote": "When deeds speak, words are nothing.",
	"author": "African proverb"
	}, {
	"quote": "Skill to do comes of doing.",
	"author": "Ralph Emerson"
	}, {
	"quote": "Wisdom is the supreme part of happiness.",
	"author": "Sophocles"
	}, {
	"quote": "All know the way; few actually walk it.",
	"author": "Bodhidharma"
	}, {
	"quote": "Great talent finds happiness in execution.",
	"author": "Johann Wolfgang von Goethe"
	}, {
	"quote": "Faith in oneself is the best and safest course.",
	"author": "Michelangelo"
	}, {
	"quote": "Courage is going from failure to failure without losing enthusiasm.",
	"author": "Winston Churchill"
	}, {
	"quote": "The two most powerful warriors are patience and time.",
	"author": "Leo Tolstoy"
	}, {
	"quote": "Anticipate the difficult by managing the easy.",
	"author": "Lao Tzu"
	}, {
	"quote": "Those who are free of resentful thoughts surely find peace.",
	"author": "Buddha"
	}, {
	"quote": "Talk doesn't cook rice.",
	"author": "Chinese proverb"
	}, {
    "quote": "Don't fight the Fed",
    "author": ""
    }, {
    "quote": "Bear markets are just a pit stop on the way to new highs",
    "author": "Genevieve Roch-Decter"
    }, {
    "quote": "The average bear market only lasts 388 days",
    "author": "Genevieve Roch-Decter"
    }, {
    "quote": "The stock market has doubled your money every ten years for the last 100 years (on average) despite recessions, depressions, World Wars, and crashes. It’s a long-term game. Zoom out.",
    "author": "Genevieve Roch-Decter"
    }, {
    "quote": "In investing what is comfortable is rarely profitable",
    "author": "Robert Arnott"
    }, {
    "quote": "Amateurs think about how much money they can make. Professionals think about how much money they could lose.",
    "author": "Jack Schwager"
    }, {
    "quote": "In trading, you have to be defensive and aggressive at the same time. If you are not aggressive, you are not going to make money, and if you are not defensive, you are not going to keep the money.",
    "author": "Ray Dalio"
    }, {
    "quote": "I'm only rich because I know when I'm wrong. I basically have survived by recognizing my mistakes.",
    "author": "George Soros"
    }, {
    "quote": "It was clear to me that other people could trade for a living, and if it was possible for other people to do it, then I could persevere long enough to figure it out.",
    "author": "Rob Booker"
    }, {
    "quote": "I just wait until there is money lying in the corner, and all I have to do is go over there and pick it up. I do nothing in the meantime.",
    "author": "Jim Rogers"
    }, {
    "quote": "Learn to take losses. The most important thing in making money is not letting your losses get out of hand.",
    "author": "Marty Schwartz"
    }, {
    "quote": "Whatever method you use to enter trades, the most critical thing is that if there is a major trend, your approach should assure that you get in that trend.",
    "author": "Richard Dennis"
    }, {
    "quote": "The fundamental law of investing is the uncertainty of the future",
    "author": "Peter Bernstein"
    }, {
    "quote": "Wall Street is the only place that people ride to in a Rolls Royce to get advice from those who take the subway",
    "author": "Warren Buffett"
    }, {
    "quote": "You only have to do very few things right in your life, so long as you don't do too many things wrong.",
    "author": "Warren Buffett"
    }, {
    "quote": "The difference between successful people and really successful people is that really successful person say no to almost everything",
    "author": "Warren Buffett"
    }, {
    "quote": "The goal of a successful trader is to make the best trades. Money is secondary.",
    "author": "Alexander Elder"
    }, {
    "quote": "There is a time to go long, a time to go short, and a time to go fishing.",
    "author": "Jesse Livermore"
    }, {
    "quote": "If most traders would learn to sit on their hands 50 percent of the time, they would make a lot more money.",
    "author": "Bill Lipschutz"
    }, {
    "quote": "Throughout my financial career, I have continually witnessed examples of other people that I have known being ruined by a failure to respect risk. If you don't take a hard look at risk, it will take you.",
    "author": "Larry Hite"
    }, {
    "quote": "Do not anticipate and move without market confirmation—being a little late in your trade is your insurance that you are right or wrong.",
    "author": "Jesse Livermore"
    }, {
    "quote": "It is the job of the market to turn the base material of our emotions into gold.",
    "author": "Andrei Codrescu"
    }, {
    "quote": "You never know what kind of setup market will present to you; your objective should be to find an opportunity where risk-reward ratio is best.",
    "author": "Jaymin Shah"
    }, {
    "quote": "Commodities tend to zig when the equity markets zag",
    "author": "Jim Roger"
    }, {
    "quote": "There are two sets of rules when it comes to money: One set of rules for the people who work for money and another set of rules for the rich who print money.",
    "author": "Robert Kiyosaki"
    }, {
    "quote": "The market can stay irrational longer than you can stay solvent",
    "author": "John Maynard Keynes"
    }, {
    "quote": "Not having a position, is a position",
    "author": "Richard Rhodes"
    }, {
    "quote": "Bull markets are born on pessimism, grow on skepticism, mature on optimism and die of euphoria.",
    "author": "John Templeton"
    }, {
    "quote": "One of the funny things about the stock market is that every time one person buys, another sells, and both think they are astute.",
    "author": "William Feather"
    }, {
    "quote": "Stock market bubbles don't grow out of thin air. They have a solid basis in reality, but reality as distorted by a misconception.",
    "author": "George Soros"
    }, {
    "quote": "Trading effectively is about assessing probabilities, not certainties.",
    "author": "Yvan Byeajee"
    }, {
    "quote": "When purchasing depressed stock in troubled companies, seek out the ones with the superior financial positions, and avoid the ones with loads of bank debt.",
    "author": "Peter Lynch"
    }, {
    "quote": "Only the game can teach you the game",
    "author": "Jesse Livermore"
    }, {
    "quote": "If you don't play the game, the game play you",
    "author": "Avon Barksdale"
    }, {
    "quote": "You don't stop a train with a brick wall; even there's a wall of selling, the train is going through that brick wall and take out a lot of bears.",
    "author": "Paul Tudor Jones"
    }, {
    "quote": "A handful of men have become very rich by paying attention to details that most others ignored",
    "author": "Henry Ford"
    }, {
    "quote": "If stock market experts were so expert, they would be buying stocks, not selling advice.",
    "author": "Norman R. Augustine"
    }, {
    "quote": "In many ways, the stock market is like the weather in that if you don't like the current conditions, all you have to do is wait a while.",
    "author": "Low Simpson"
    }, {
    "quote": "The intelligent investor is a realist who sells to optimists and buys from pessimists",
    "author": "Benjamin Graham"
    }, {
    "quote": "The main purpose of the stock market is to make fools of as many men as possible",
    "author": "Bernard Baruch"
    }, {
    "quote": "Stocks are bought not in fear but in hope. They are typically sold out of fear.",
    "author": "Justin Mamis"
    }, {
    "quote": "A company has only so much money and managerial time. Winning leaders invest where the payback is the highest. They cut their losses everywhere else.",
    "author": "Jack Welch"
    }, {
    "quote": "We are in the business of making mistakes. Winners make small mistakes; losers make big mistakes.",
    "author": "Ned Davis"
    }, {
    "quote": "Cut your losses. Cut your losses. Cut your losses. Then maybe you have a chance.",
    "author": "Ed Seykota"
    }, {
    "quote": "We want to perceive ourselves as winners, but successful traders are always focusing on their losses.",
    "author": "Peter Borish"
    }, {
    "quote": "My favorite things in life don't cost any money. It's really clear that the most precious resource we all have is time.",
    "author": "Steve Jobs"
    }, {
    "quote": "The big money is not in the buying or the selling, but in the waiting.",
    "author": "Charlie Munger"
    }, {
    "quote": "Seek advice on risk from the wealthy who still take risks, not friends who dare nothing more than a football bet.",
    "author": "John Paul Getty"
    }, {
    "quote": "You make most of your money in a bear market; you just don't realize it at the time.",
    "author": "Shelby Cullom Davis"
    }, {
    "quote": "Investing is the intersection of economics and psychology",
    "author": "Seth Klarman"
    }, {
    "quote": "The hard, cold reality of trading is that every trade has an uncertain outcome.",
    "author": "Mark Douglas"
    }, {
    "quote": "The consistency you seek is in your mind, not in the markets.",
    "author": "Mark Douglas"
    }, {
    "quote": "The question should not be how much I will profit on this trade! The true question is; will I be fine if I don't profit from this trade.",
    "author": "Yvan Byeajee"
    }, {
    "quote": "If you don't find a way to make money while you sleep, you will work until you die.",
    "author": "Warren Buffett"
    }, {
    "quote": "Always start at the end before you begin. Professional investors always have an exit strategy before they invest.",
    "author": "Robert Kiyosaki"
    }, {
    "quote": "To avoid whipsaw losses, stop trading.",
    "author": "Ed Seykota"
    }, {
    "quote": "If I have positions going against me, I get right out; if they are going for me, I keep them.",
    "author": "Paul Tudor Jones"
    }, {
    "quote": "I will keep cutting my position size down as I have losing trades. When I am trading poorly, I keep reducing my position size. That way, I will be trading my smallest position size when my trading is worst.",
    "author": "Paul Tudor Jones"
    }, {
    "quote": "Have at least three reasons to get into a trade, but zero reasons not to",
    "author": ""
    }, {
        "quote": "Over the last 50 years there have been 5 major bear markets. Their Bear market rallies lasted on average 3 weeks and delivered 7-8% return. Most of those returns were achieved the first week.",
        "author": "Macro Alf"
    }, {
        "quote": "Recessions reduce inflation: Last 100 years, 11/11 cases entered a recession with inflation above 3%, came out of it lower. Took avg 16.2 months to slow inflation down from the peak. The average decline was -6.8%.",
        "author": "Macro Alf"
    }, {
        "quote": "Never 'revenge trade', sit back, diffuse the emotions, and assess what you did wrong. Then build on that slowly.",
        "author": ""
    }, {
        "quote": "Trading long-term is a hell of a lot harder than you think it is going to be. It's not just the losses that get to you, it's the big wins you miss out on that hurt without closure.",
        "author": ""
    }, {
        "quote": "Trading comes with a gambler's nausea, you have to learn to function mechanically while suffering it.",
        "author": ""
    }, {
        "quote": "Generational wealth is built during bear markets",
        "author": "Genevieve Roch-Decter"
    }, {
        "quote": "Almost every financial blow-up is because of excessive leverage",
        "author": "Seth Klarman"
    },




]
export default quotes