import React from 'react';
import { Component } from 'react';
import '../components/styles.scss';


// Timezone strings - https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// Changed update setInterval to 30 seconds (30000) instead of every second (1000)

class WorldClocks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date()
    };

    this.updateDate = this.updateDate.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(this.updateDate, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateDate() {
    this.setState({
      date: new Date()      
    });
    this.setState({}); //testing if this works to refresh the page so background color changes
  }

// Add all the world clocks wanted in here and in order of display
  render() {    
    return (
      <div>
        <div>{this.props.name}</div>
        <div className = "worldClocks">
        <Clock
            name="ASX: "        
            date={this.state.date}
            country={"en-GB"}//setting this to GB makes it 24hour clock, no idea why.
            timeZone={"Australia/Sydney"}
            hour={'2-digit'}
            minute={'2-digit'}
            openHours="10 til 16"
            openTime={"10:00:00"}
            closeTime={"16:00:00"}       
          />
        <Clock
            name="TSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Tokyo"}
            openTime={"09:00:00"}
            closeTime={"15:00:00"}
            openHours="9 til 15"
          />
        <Clock
            name="SSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Shanghai"}
            openTime={"09:15:00"}
            closeTime={"15:00:00"}
            openHours="9:15 til 15"

          />
        <Clock
            name="HKE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Hong_Kong"}
            openTime={"09:30:00"}
            closeTime={"16:00:00"}
            openHours="9:30 til 16"
          />
        <Clock
            name="SGX: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Singapore"}
            openTime={"09:00:00"}
            closeTime={"17:00:00"}
            openHours="9 til 17"
          />
        <Clock
            name="NSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Kolkata"}
            openTime={"09:00:00"}
            closeTime={"15:30:00"}
            openHours="9 til 15:30"
          />
        <Clock
            name="DIFX: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Asia/Dubai"}
            openTime={"10:00:00"}
            closeTime={"14:00:00"}
            openHours="10 til 14"
          />
        <Clock
            name="RTS: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Europe/Moscow"}
            openTime={"09:30:00"}
            closeTime={"19:00:00"}
            openHours="9:30 til 19"
          />
        <Clock
            name="FWB: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Europe/Berlin"}
            openTime={"08:00:00"}
            closeTime={"20:00:00"}
            openHours="8 til 20"
          />
        <Clock
            name="LSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Europe/London"}
            openTime={"08:00:00"}
            closeTime={"16:30:00"}
            openHours="8 til 16:30"
          />
        <Clock
            name="JSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"Africa/Johannesburg"}
            openTime={"09:00:00"}
            closeTime={"17:00:00"}
            openHours="9 til 17"
          />
        <Clock
            name="BM/F(10): "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"America/Sao_Paulo"}
            openTime={"10:00:00"}
            closeTime={"17:00:00"} 
            openHours="10 til 17"          
          />
        <Clock
            name="NYSE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"America/New_York"}
            openTime={"09:30:00"}
            closeTime={"16:00:00"}
            openHours="9:30 til 16"
          />
        <Clock
            name="TXE: "
            date={this.state.date}
            country={"en-GB"}
            timeZone={"America/Toronto"}
            openTime={"09:30:00"}
            closeTime={"16:00:00"}
            openHours="9:30 til 16"
          />

        </div>
      </div>
    );
  }
}

class Clock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: true
    };

    this.showClock = this.showClock.bind(this);
    const timeNow = this.props.date.toLocaleTimeString(this.props.country,{timeZone: this.props.timeZone})
      if ((timeNow >= this.props.openTime) && (timeNow <= this.props.closeTime)) this.state.color='green'
      else this.state.color='#1b1b38'
      //console.log('For:', this.props.name)
      //console.log('timeNow:', timeNow)
      //console.log('openTime:', this.props.openTime) 
      //console.log('closeTime:', this.props.closeTime)
      //console.log('difference from Open:', (this.props.openTime - this.props.closeTime))
      //console.log('difference from Close:', (this.props.closeTime - timeNow))    
  }

  showClock() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }

  // This bit sends back the worldclock and the time one by one
  render() {      
    return (
      <div>
        <div style={{backgroundColor: this.state.color}}>
            | {this.props.name} 
            {this.props.date.toLocaleTimeString(this.props.country,{timeZone: this.props.timeZone})}
            &#160;
            <div style={{textAlign: 'center'}}>
            {this.props.openHours}
            </div>                    
        </div>
      </div>
    )
  }
}

export default WorldClocks;